import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import {
  SectionContainer,
  SectionContent,
  SectionWrapper
} from "../common/Section";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import { CallToAction, LargeCallToAction } from "../components/CallToAction";
import { TestimonialsCarousel } from "../components/Carousel";
import { PageHero } from "../components/Hero";
import { PromotionsItem, Stack } from "../components/Stack";


const PromotionsPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      promotionspage: mdx(
        frontmatter: {
          title: {
            eq: "Promotions"
          }
        }
      ) {
        frontmatter {
          pageHeading
          pageDescription
        }
      }

      promotions: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "promotion"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
              description
              amount
            }
          }
        }
      }

      testimonials: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "testimonial"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              source
              reviewer
              review
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head
        title="Promotions"
      />

      <SectionWrapper
        background="background.linen"
        padding="0 0 45px"
      >
        <SectionContainer>
          <PageHero
            heading={ data.promotionspage.frontmatter.pageHeading }
            description={ data.promotionspage.frontmatter.pageDescription }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="78px 0 65px"
      >
        <SectionContainer>
          <Stack
            align="center"
            dividerVisible
          >
            { data.promotions.edges.map(( promotion: any, _index: number ) => (
              <PromotionsItem
                key={ _index }
                heading={ promotion.node.frontmatter.heading }
                description={ promotion.node.frontmatter.description }
                amount={ promotion.node.frontmatter.amount }
              />
            ))}
          </Stack>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.linkwater"
      >
        <SectionContainer>
          <LargeCallToAction
            heading="Servicing San Diego County for Over 20 Years. Our service professionals have earned over 3,700+ reviews !"
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            heading="Our service and professionals have earned over 3,700+ reviews!"
            headingWidth={{
              base: '100%',
              lg: '700px'
            }}
            headingMargin="0 auto"
            description={[
              "Vester Pest Control has helped thousands of property owners over the past 20 years. Here are what some of our customers have to say."
            ]}
            descriptionWidth={{
              base: '100%',
              sm: '580px'
            }}
          />
        </SectionContainer>
        <TestimonialsCarousel
          nodes={ data.testimonials.edges }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading="Request a Free Quote"
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export default PromotionsPage;
